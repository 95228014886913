(function () {
	'use strict';

	angular
		.module('smartermail')
		.controller('directoryServiceProviderController', directoryServiceProviderController);

	function directoryServiceProviderController($scope, $http, $mdDialog, errorHandling, existingProvider) {
		/* jshint validthis:true */
		var vm = this;
		vm.title = 'directory-Service-Provider';
		vm.cancel = cancel;
		vm.validateCredentialsAndSave = validateCredentialsAndSave;
		vm.saveCredentials = true;
		vm.isNew = existingProvider === undefined;
		if (!vm.isNew && existingProvider) {
			vm.providerName = existingProvider.providerName;
			vm.ldapBinding = existingProvider.ldapServiceProviderBinding;
			vm.ldapUser = existingProvider.ldapUsername;
			vm.saveCredentials = existingProvider.saveCredentials;
			vm.fqdn = existingProvider.fqdn;
			vm.providerType = existingProvider.providerType;
			vm.serverName = existingProvider.serverName;
			vm.organizationalUnit = existingProvider.organizationalUnit;
		} else {
			vm.providerType = 1;
		}
		activate();

		////////////////

		function activate() {
		}

		function cancel(ev) {
			$mdDialog.cancel();
		}
		vm.delete = function () {
			$mdDialog.hide({ delete: true });
		}
		function validateCredentialsAndSave(ev) {
			if ($scope.pageForm && $scope.pageForm.$dirty) {

				$http.post("~/api/v1/settings/domain/auth-provider-validate",
					JSON.stringify({
						ldapServiceProviderBinding: vm.ldapBinding,
						ldapUsername: vm.ldapUser,
						ldapPassword: vm.ldapPass,
						providerType: vm.providerType,
						serverName: vm.serverName,
						fqdn: vm.fqdn,
						organizationalUnit: vm.organizationalUnit
					}))
					.then(function (success) {
						$mdDialog.hide({ 
							providerType: vm.providerType,
							ldapServiceProviderBinding: vm.ldapBinding, 
							ldapUser: vm.ldapUser, 
							ldapPassword: vm.ldapPass, 
							rememberCredentials: vm.saveCredentials,
							providerName: vm.providerName,
							serverName: vm.serverName,
							fqdn: vm.fqdn,
							organizationalUnit: vm.organizationalUnit
						});
					},
						function (error) {
							vm.errorMessage = error.data.message;
						});
			} else {
				cancel();
			}
		}
	}
})();
