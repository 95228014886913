(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userEditAppointmentTypeController", userEditAppointmentTypeController);

    function userEditAppointmentTypeController($scope, $mdDialog, $filter, $timeout, $translate, coreData, userDataService, coreLicensing, appointDuration, appointLocation, title, newItem, isOnline, bookingHorizonLimit)
    {
        $scope.newItem = newItem;
        $scope.title = title;
        $scope.appointDuration = appointDuration;
        $scope.appointLocation = appointLocation;
        $scope.bookingHorizonLimit = bookingHorizonLimit;
        $scope.onlineMeeting = isOnline;

        // Functions
        $scope.save = save;
        $scope.delete = deleteThis;
        $scope.cancel = cancel;
        $scope.setOnlineMeeting = setOnlineMeeting;
        $scope.onlineMeetingAllowed = true;


        activate();
        /////////////////////////

        function activate() {
            const features = userDataService.user.settings.features;
            $scope.onlineMeetingAllowed = features.enableWebConferencing && coreLicensing.edition !== 1;
        }

        function save() {
            $timeout(function () {
                if ($scope.userForm.$valid) {
                    var appointmentType = {
                        name: $scope.title,
                        duration: $scope.appointDuration,
                        location: $scope.appointLocation,
                        isOnline: $scope.onlineMeeting,
                        bookingHorizonLimit: $scope.bookingHorizonLimit

                    };
                    $mdDialog.hide({ deleteItem: false, appointmentType: appointmentType });
                }
            });
        }

        function setOnlineMeeting() {
            if ($scope.onlineMeeting) {
                $scope.appointLocation = $translate.instant("ONLINE_MEETING");
            } else {
                $scope.appointLocation = appointLocation;
            }
        }

        function deleteThis() {
            $mdDialog.hide({ deleteItem: true });
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();