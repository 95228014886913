(function () {
	"use strict";
	
	angular
		.module("smartermail")
		.controller("systemPortsModalController", systemPortsModalController);

	function systemPortsModalController($scope, $mdDialog, $filter, $http, $q, info, errorHandling) {
		var vm = this;
		$scope.encryptionsEnum = [
			{ index: 0, label: $filter("translate")("NONE"), isSsl: false, isTls: false },
			{ index: 1, label: $filter("translate")("ENCRYPTION_SSL"), isSsl: true, isTls: false },
			{ index: 2, label: $filter("translate")("ENCRYPTION_TLS"), isSsl: false, isTls: true }
		];
		var portInfo = angular.copy(info);
		var id = portInfo.id || null;
		$scope.isNew = portInfo.isNew || false;
		$scope.protocol = portInfo.type;
		$scope.name = portInfo.name;
		$scope.port = portInfo.port;
		$scope.encryption = getEncryption();
		$scope.ips = $filter("orderBy")(portInfo.ips, "ipAddress");
		$scope.checkedIps = portInfo.ips.reduce(function (sum, value) { return value.selected ? ++sum : sum }, 0);
		vm.certificatePath = portInfo.certificatePath || "";
		vm.password = portInfo.password || "";
		$scope.checkboxCount = 0;
		// Functions
		$scope.startsave = startsave;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.checked = checked;
		$scope.deleteItem = deleteItem;
		$scope.checkForDuplicates = checkForDuplicates;
		vm.setNameAndPort = setNameAndPort;
		vm.checkForDuplicates = checkForDuplicates;
		vm.boundIpAddresses = [];
		vm.duplicateEntryError = false;
		vm.portOrIPChanged = portOrIPChanged();
		vm.originalSelections = [];
		vm.duplicateIps = [];
		activate();

		/////////////////////

		function activate() {
			if (portInfo.isNew)
				setNameAndPort();

			//uncommenting this hides the error on load
			//vm.originalSelections = $scope.ips.filter(f => f.selected).map(function (ip) { return ip.ipAddress; });

			checkForDuplicates();
		}

		function getVerificationString(code) {
			switch (code) {
				case 0:
					return $filter("translate")("CERTIFICATE_NOT_FOUND");
				case 1:
					return $filter("translate")("NOT_A_VALID_CERTIFICATE");
				case 2:
					return $filter("translate")("VALID_CERTIFICATE");
				default:
					return $filter("translate")("NOT_VERIFIED");
			}
		}

		function getEncryption() {
			if (portInfo.isSSL) return $scope.encryptionsEnum[1].index;
			if (portInfo.isTls) return $scope.encryptionsEnum[2].index;
			return $scope.encryptionsEnum[0].index;
		}

		function getDefaultName(protocol) {
			var result = $filter("translate")(protocol);
			if ($scope.encryption === 1) result += " " + $scope.encryptionsEnum[1].label;
			if ($scope.encryption === 2) result += " " + $scope.encryptionsEnum[2].label;
			return result;
		}

		function setNameAndPort() {
			var isSSL = $scope.encryption === 1;
			switch ($scope.protocol) {
				case 0:
					$scope.port = isSSL ? 465 : 25;
					if (portInfo.isNew) $scope.name = getDefaultName("SMTP");
					break;
				case 1:
					$scope.port = isSSL ? 995 : 110;
					if (portInfo.isNew) $scope.name = getDefaultName("POP");
					break;
				case 2:
					$scope.port = isSSL ? 993 : 143;
					if (portInfo.isNew) $scope.name = getDefaultName("IMAP");
					break;
				case 3:
					$scope.port = 389;
					if (portInfo.isNew) $scope.name = $filter("translate")("LDAP");
					break;
				case 4:
					$scope.port = isSSL ? 465 : 587;
					if (portInfo.isNew) $scope.name = getDefaultName("SUBMISSION");
					break;
				case 5:
					$scope.port = 5222;
					if (portInfo.isNew) $scope.name = $filter("translate")("XMPP");
					break;
			}
		}

		function startsave(event, skipIPCheck) {
			vm.boundIpAddresses = [];
			angular.forEach($scope.ips,
				function (value) {
					if (value.selected)
						vm.boundIpAddresses.push(value.ipAddress);
				});
			
			if (!skipIPCheck) {
				for (var i = 0; i < info.ports.length; i++) {
					if ($scope.port == info.ports[i].port && $scope.id !== info.ports[i].id) {
						var ips = info.ports[i].ips;
						for (var y = 0; y < vm.boundIpAddresses.length; y++) {
							var results = ips.filter(function(x){ return x.ipAddress == vm.boundIpAddresses[y] });
							if (results.length > 0) {
								vm.duplicateEntryError = true;
							}
						}
					}
				}
			}

			vm.verificationStr = "";
			if ($scope.encryption !== 0)
				verify(event)
					.then(function (result) {
						if (result.success)
							save(event);
						else
							vm.verificationStr = result.message;
					}, errorHandling.report);
			else {
				save(event);
			}
		}

		function save(event) {
			var info = {
				id: id,
				type: $scope.protocol,
				name: $scope.name,
				port: $scope.port,
				isSSL: $scope.encryption === 1,
				isTls: $scope.encryption === 2,
				certificatePath: vm.certificatePath,
				password: vm.password,
				boundIpAddresses: vm.boundIpAddresses
			};

			var params = {};
			if (portInfo.isNew)
				params.toAdd = [info];
			else
				params.bindingPorts = [info];
			params = JSON.stringify(params);

			$http
				.post("~/api/v1/settings/sysadmin/ip-binding-ports", params)
				.then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess() {
				$mdDialog.hide();
			}
		}
		function deleteItem() {
			$mdDialog.hide({delete: true});
		}
        function verify() {
            var defer = $q.defer();
			var params = JSON.stringify({
				id: id,
				path: vm.certificatePath,
				password: vm.password
			});

			$http
				.post("~/api/v1/settings/sysadmin/verify-certificate", params)
                .then(onVerifySuccess, onVerifyError);
            return defer.promise;

            function onVerifySuccess(result) {
                if (result.data.result === 2) {
                     defer.resolve({success:true});
                }
                else {
                    defer.resolve({ success: false, message: getVerificationString(result.data.result) });
                }

            }

            function onVerifyError(result) {
                defer.reject(result.data.message);
			}
		}

		function cancel() {
			$mdDialog.cancel();
		}

		var lastChecked;

		function checked(ev, ip) {
			portOrIPChanged();
			document.getSelection().removeAllRanges();
			if (ip.selected)
				$scope.checkedIps++;
			else
				$scope.checkedIps--;

			// If a shift select is occurring
			if (lastChecked && ip.ipAddress != lastChecked && event.shiftKey) {
				var setChecked = ip.selected;
				var checking = false;
				for (var i = 0; i < $scope.ips.length; i++) {
					var ipToCheck = $scope.ips[i];
					if (checking) {
						if (ipToCheck.ipAddress !== ip.ipAddress) ipToCheck.selected = setChecked;
						// If we reached the end of the shift selection
						if (ipToCheck.ipAddress == lastChecked || ipToCheck.ipAddress == ip.ipAddress) break;
					} else if (ipToCheck.ipAddress == lastChecked || ipToCheck.ipAddress == ip.ipAddress) { // We reached the beginning of the shift selection
						if (ipToCheck.ipAddress !== ip.ipAddress) ipToCheck.selected = setChecked;

						checking = true;
					}
				}
			}
			lastChecked = ip.ipAddress;

			checkForDuplicates(ip);
		}

		function checkForDuplicates(ip) {

			//If IP is null then loop through all selections
			if (!ip) {
				vm.duplicateIps = [];
				portInfo.ips.forEach(function (ip) {
					checkForDuplicates(ip);
				});
			} else {
				//
				if (ip.selected) {
					var currentPort = $scope.port;
					var duplicateIp = info.ports.some(p => p.id != id && p.ips.some(i => i.selected && (i.ipAddress == ip.ipAddress && currentPort == p.port)));
					var originallySelected = vm.originalSelections.some(i => i == ip.ipAddress);
					if (duplicateIp && !originallySelected)
						vm.duplicateIps.push(ip.ipAddress);
				} else {
					vm.originalSelections = vm.originalSelections.filter(v => v !== ip.ipAddress);
					vm.duplicateIps = vm.duplicateIps.filter(v => v !== ip.ipAddress);
				}
			}


			

			vm.duplicateEntryError = vm.duplicateIps.length > 0;
			if (vm.duplicateEntryError)
				console.log("duplicate bound ips", vm.duplicateIps);
		}

		function portOrIPChanged() {
			vm.duplicateEntryError = false;
		}
	}
})();